@font-face {
  font-family: "Open Sans Bold";
  font-weight: 700;
  src:
    local("Open Sans Bold"),
    local("Open-Sans-Bold"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Bold Italic";
  font-style: italic;
  font-weight: 700;
  src:
    local("Open Sans Bold Italic"),
    local("Open-Sans-BoldItalic"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-BoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Extra Bold";
  font-weight: 800;
  src:
    local("Open Sans Extra Bold"),
    local("Open-Sans-ExtraBold"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-ExtraBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Extra Bold Italic";
  font-style: italic;
  font-weight: 800;
  src:
    local("Open Sans Extra Bold Italic"),
    local("Open-Sans-ExtraBoldItalic"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-ExtraBoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Light";

  font-weight: 300;
  src:
    local("Open Sans Light"),
    local("Open-Sans-Light"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-Light.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Light Italic";
  font-weight: 300;
  font-style: italic;
  src:
    local("Open Sans Light Italic"),
    local("Open-Sans-LightItalic"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-LightItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  font-weight: 400;
  src:
    local("Open Sans Regular"),
    local("Open-Sans-Regular"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Medium";
  font-weight: 500;
  src:
    local("Open Sans Medium"),
    local("Open-Sans-Medium"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-Medium.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans Medium Italic";
  font-weight: 500;
  font-style: italic;
  src:
    local("Open Sans Medium Italic"),
    local("Open-Sans-MediumItalic"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-MediumItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans SemiBold";
  font-weight: 600;
  src:
    local("Open Sans SemiBold"),
    local("Open-Sans-SemiBold"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-SemiBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Open Sans SemiBold Italic";
  font-weight: 600;
  font-style: italic;
  src:
    local("Open Sans SemiBold Italic"),
    local("Open-Sans-SemiBoldItalic"),
    /* Modern Browsers */ url("/assets/fonts/OpenSans-SemiBoldItalic.ttf")
      format("truetype");
}
