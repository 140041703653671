// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use "~@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $catMan-ui-primary: mat.define-palette(mat.$indigo-palette);
// $catMan-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $catMan-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $catMan-ui-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $catMan-ui-primary,
//       accent: $catMan-ui-accent,
//       warn: $catMan-ui-warn,
//     ),
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($catMan-ui-theme);

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./normalize";
//@import "~@ng-select/ng-select/themes/default.theme.css";

// @media (-webkit-device-pixel-ratio: 1.25) {
//   html {
//     zoom: 0.8;
//   }
// }

// @media (-webkit-device-pixel-ratio: 1.5) {
//   html {
//     zoom: 80%;
//   }
// }

@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  display: initial;
}

.header {
  height: 50px !important;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-icons .icon-align {
  margin-left: 25px;
}

.header-icons .icon-align .icon-size {
  font-size: 20px;
  color: white;
}

/* remove the original arrow */
select.input-lg {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}

select + i.fa {
  float: right;
  margin-top: -26px;
  margin-right: 1px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
  font-size: 11px;
  color: #505050;
}

.input-field {
  border: 1px solid #707070;
  border-radius: 5px;
  width: 146px;
  height: 40px;
  padding-right: 23px;
  background: #fafbfc 0% 0% no-repeat padding-box;
}

.drop-dwn {
  border: 1px solid #707070;
  border-radius: 5px;
  width: 102px;
  height: 40px;
  background: #fafbfc 0% 0% no-repeat padding-box;
}

.capsule {
  // margin-left: 20px;
  border-radius: 26px;
  /* height: 49px; */
  width: 173px;
  height: 42px;
  text-align: center;
  /* margin-bottom: 25px; */
  line-height: 42px;
  vertical-align: middle;
}

// font-fam {
//   font-family: sans-serif;
// }

.mar-r {
  margin-right: 10px;
}

.mar-l {
  margin-left: 10px;
}

html,
body,
button,
h5,
input {
  // height: 95vh;
  font-family: $opensans, sans-serif !important;
}

body {
  margin: 0;
  background: #fafbfc 0% 0% no-repeat padding-box;
}

body.hide-body-scrollbar {
  overflow-y: hidden;
  height: 100%;
}

.show-body-scrollbar {
  overflow-y: auto;
  height: auto;
}

.mandatory {
  color: #d34243;
  padding-left: 2px;
}

.label {
  margin-bottom: 5px;
}

.flex-1 {
  flex: 1;
}

.btn-upload {
  background-color: white;
  color: #f49c00;
  border-radius: 5px;
  border-color: #f49c00;
  height: 40px;
  padding: 0px 35px;
  font-size: 14px;

  &:hover,
  &:focus {
    color: #f49c00;
  }
}

.btn-download {
  background-color: white;
  color: #a63132;
  border-radius: 5px;
  border-color: #f49c00;
  border: 1px solid #a63132;
  border-radius: 5px;
  height: 40px;
  padding: 0px 35px;
  font-size: 14px;

  &:hover,
  &:focus {
    color: #a63132;
  }
}

.foot-content {
  justify-content: space-evenly;
  box-shadow: 0px 1px 13px #eaeaea;
  background-color: white;
  display: flex;
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0px;
}

.bottom-section {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: flex-end;
  padding: 0px 30px;
}

.arrow-forward {
  width: 14px;
  margin-top: 2px;
  margin-left: 20px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#custom-scrollbar {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: #e9e9e9;
    // background: #323232;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    // background: #f1f1f1;
    box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    // background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e9e9e9;
    // background: #555;
  }
}

.main-container {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px !important;
    background: #444444 !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tip-Guide {
  position: sticky !important;
  z-index: 4 !important;
  box-shadow: 0px 1px 13px #857a7a !important;
  background: white !important;
}

.guide-tp {
  position: absolute;
  z-index: 4;
}

// * {
//   padding: 0;
//   margin: 0;
// }

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  @apply text-xs xl:text-xs 2xl:text-sm;
}

.pi-sort-alt:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  content: "\f0dc" !important;
  color: #adadad !important;
}

.pi-sort-amount-up-alt:before {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  font-style: normal !important;
  content: "\f0de" !important;
  color: #adadad !important;
}

.pi-sort-amount-down:before {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
  font-style: normal !important;
  content: "\f0dd" !important;
  color: #adadad !important;
}

.experimentsTable.p-datatable table,
.simulation-table.p-datatable table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}

.p-datatable .p-sortable-column.p-highlight {
  color: inherit !important;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: inherit !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: inherit !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
  outline: 0 none;
}

.experimentsTable.p-datatable p-sorticon,
.simulation-table.p-datatable p-sorticon {
  float: right !important;
}

.overview-panel.p-overlaypanel:before {
  margin-left: -15px !important;
}

.overview-panel.p-overlaypanel:after {
  margin-left: -13px !important;
}

.cdk-overlay-container {
  z-index: 100000 !important;
}

.itemsPanel.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #333;
  background-color: #ebf5ff;
}

.itemsPanel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333333;
  background: #ffffff;
}

.filterDropdown.p-dropdown:focus {
  padding: 2px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.itemsPanel.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font: normal normal 12px/15px Open Sans;
}

.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  color: #333 !important;
  background-color: #ebf5ff !important;
}

.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333333;
  background: transparent !important;
}

.ng-dropdown-panel {
  z-index: 10000000 !important;
  // width: 100px !important;
  // border: 1px solid #787878;
}

// base grid creation tooltip
.tooltip_ui .p-tooltip-text {
  font-size: 12px;
  color: #000000 !important;
  background-color: #fff !important;
  text-align: center;
}

// base grid creation tooltip
body.dark .tooltip_ui .p-tooltip-text {
  font-size: 12px;
  color: #ffffff !important;
  background-color: #4c4c4c !important;
  text-align: center;
}

.tooltip_ui .p-tooltip-arrow {
  border-color: #fff !important;
  border-top-color: #000 !important;
  border-bottom-color: #000 !important;
}

// data exploration date filter styles
.dateRangePicker-wrapper .dateRangePicker {
  top: 40px !important;
  z-index: 1000 !important;

  .form-inputs {
    background-color: transparent;
    color: #000000;

    .col {
      padding-left: 3em !important;
    }
  }

  .form-inputs .form-control {
    color: #000000 !important;
    pointer-events: none;

    &:focus {
      box-shadow: none;
      border: none;
    }
  }
}

.popup-date-range-picker .dateRangePicker-wrapper .dateRangePicker {
  top: 150px !important;
  z-index: 1000 !important;
  right: 0 !important;
  position: fixed !important;
  left: 0 !important;
}

.dateRangePicker-wrapper .dateRangePicker:after {
  display: none;
}

predefined-date-ranges {
  .btn-outline-secondary,
  .btn-outline-secondary:hover {
    margin-right: 5px;
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    color: #19181a;
    border-radius: 6px;
    width: 76px;
    height: 34px;
  }

  .btn-primary,
  .btn-primary:disabled,
  .btn-primary:hover {
    background-color: #e5b611;
    color: #000000;
    border-color: #e5b611;
    font: normal normal 600 12px/17px Open Sans;
    border-radius: 6px;
    width: 76px;
    height: 34px;
  }
}

.clustering-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.clustering-checkbox.mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background {
  background-color: #d6a732;
}

.calendar .table td.selected,
.calendar .table td.selected.in-selected-range {
  background-color: #d6a732 !important;
  border-radius: 4px 0px 0px 4px !important;
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
}

.calendar .table td.in-selected-range {
  background-color: #fdf8e9 !important;
}

.calendar .material-icons {
  color: #d6a732 !important;
}

//user management styles
.add-user-dropdown.p-dropdown {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e2eb;
  border-radius: 4px;
}

.add-user-dropdown.p-dropdown:not(.p-disabled):hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e2eb;
  border-radius: 4px;
}

.add-user-dropdown.p-dropdown .p-dropdown-item {
  font: normal normal normal 14px/19px Open Sans;
}

.add-user-dropdown.p-dropdown .p-inputtext {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
}

.add-user-dropdown.p-dropdown .p-dropdown-trigger-icon {
  font-size: 10px;
}

.add-user-dropdown.p-dropdown .p-dropdown-label.p-placeholder {
  font: normal normal normal 14px/19px Open Sans;
  color: #d1d1d1;
}

.add-user-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
}

.add-user-dropdown-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #000000 !important;
  background: #ffffff !important;
}

.add-user-dropdown.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid #e0e2eb !important;
}

// removing all p-dropdowns box shadow
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.user-management-dialog.p-dialog {
  box-shadow: 0px 0px 10px #0000003d;
  border: none;
  background-color: #ffffff;
  border-radius: 8px;
}

.user-management-dialog.p-dialog .p-dialog-header {
  padding: 0.5em 0em;
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  margin: 0px 16px;
  border-color: #e0e2eb;
}

.user-management-dialog.p-dialog .p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}

.user-management-dialog.p-dialog .p-dialog-title {
  font: normal normal 600 16px/22px Open Sans;
  color: #000000;
}

.experiment-details-dialog.p-dialog .p-dialog-content {
  padding-top: 5px;
}

// user management table styles
.user-management-table.p-datatable .p-datatable-thead tr th {
  border: 1px solid #e3e3e3;
  border-width: 1px 0px 1px 0px;
  font: normal normal 600 14px/19px Open Sans;
  background-color: #ffffff;
  color: #8d8d8d;
}

.user-management-table.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #ffffff;
  color: #8d8d8d;
}

.user-management-table.p-datatable .p-datatable-tbody tr td {
  border: 1px solid #e3e3e3;
  border-width: 1px 0px 1px 0px;
}

.user-management-table.p-datatable .p-datatable-tbody > tr > td {
  font: normal normal 600 14px/19px Open Sans;
  color: #0a0a0a;
}

.user-management-table.p-datatable .p-paginator {
  background-color: #ffffff;
  font: normal normal normal 13px/18px Open Sans;
  justify-content: right;
  border: none;
  margin-top: 10px;
}

.user-management-table.p-datatable
  .p-paginator
  .p-paginator-pages
  .p-paginator-page.p-highlight {
  background: #f6f6f9 0% 0% no-repeat padding-box;
  color: initial;
}

.user-management-table.p-datatable .p-datatable-thead > tr > th.actions-th,
.user-management-table.p-datatable .p-datatable-tbody > tr > td.actions-td {
  text-align: center !important;
  margin: auto;
  display: table-cell;
}

// user management table checkbox styles
.user-management-table.p-datatable .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.user-management-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight {
  background: #e5b611 0% 0% no-repeat padding-box;
  border: 1px solid #e5b611;
}

.user-management-table.p-datatable
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: #e5b611 0% 0% no-repeat padding-box !important;
  border: 1px solid #e5b611 !important;
}

.user-management-table.p-datatable
  .p-checkbox
  .p-checkbox-box
  .p-checkbox-icon {
  color: #000000;
}

// user management dialog checkbox styles
.user-management-dialog.p-dialog
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  box-shadow: none;
  border: 1px solid #e5b611;
}

.user-management-dialog.p-dialog .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.user-management-dialog.p-dialog .p-checkbox .p-checkbox-box.p-highlight {
  background: #e5b611 0% 0% no-repeat padding-box;
  border: 1px solid #e5b611;
}

.user-management-dialog.p-dialog
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  background: #e5b611 0% 0% no-repeat padding-box !important;
  border: 1px solid #e5b611 !important;
}

.user-management-dialog.p-dialog .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #000000;
}

.user-management-dialog.p-dialog {
  .p-dialog-footer {
    border: none;
    padding: 0px;

    button {
      margin: 0px;
    }
  }
}

.add-edit-key-account.p-dialog .p-dialog-content {
  overflow-y: hidden;
}

.user-management-table.p-datatable .p-paginator .p-inputwrapper {
  position: absolute;
  left: 50px;
}

.user-management-table.p-datatable .p-paginator .p-dropdown {
  height: 23px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.user-management-table.p-datatable .p-paginator .p-inputtext {
  font: normal normal 600 12px/17px Open Sans;
  color: #1e2228;
  padding: 2px 4px 5px 5px;
}

.user-management-table.p-datatable .p-paginator .p-dropdown-trigger-icon {
  font-size: 12px;
}

.user-management-table.p-datatable .p-paginator .p-dropdown-trigger {
  width: 20px;
  height: 20px;
}

.user-management-table.p-datatable
  .p-paginator
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  font: normal normal 600 12px/17px Open Sans;
  color: #1e2228;
}

.admin-dialog {
  .p-dialog-header {
    background-color: white !important;
  }
}

.door-handle-options-panel.p-dropdown-panel {
  border: 1px solid #787878 !important;
  margin-top: -2px;
}

.door-handle-options-panel .p-dropdown-items .p-dropdown-item {
  background: #1f1f21 0% 0% no-repeat padding-box !important;
  font: normal normal normal 11px/15px Open Sans;
  color: #ffffff !important;
}

.door-handle-options-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.door-handle-options-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #3e3e3e !important;
  color: #ffffff !important;
}

.door-handle-options-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.door-handle-options-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):hover {
  background-color: #323232 !important;
  color: #ffffff !important;

  .btn:focus {
    outline: 0 none !important;
    outline-offset: 0px !important;
    box-shadow: none !important;
  }
}

.plans-overlay {
  .p-overlaypanel {
    background-color: #1f1f21 0% 0% no-repeat padding-box !important;
    width: 50px !important;
  }

  .p-overlaypanel-content {
    padding: 0px !important;
    text-align: center !important;
  }

  &::-webkit-scrollbar-track {
    background-color: #1f1f21 !important;
  }

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 15px !important;
    padding-right: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070 !important;
  }

  &:before {
    content: none !important;
  }

  &:after {
    content: none !important;
  }
}

.main-dropdown {
  margin-top: 10px !important;
  border: none !important;
  border-radius: 4px !important;

  .p-overlaypanel-content {
    background: #323232 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none !important;
    padding: 4px !important;
  }

  &:after {
    border-bottom-color: #323232 !important;
  }
}

.segment-dropdown-list {
  .mat-menu-content {
    border-radius: 4px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    max-height: 158px !important;
    border-radius: 4px !important;
    border: none !important;
    padding-right: 8px !important;

    &::-webkit-scrollbar-track {
      background-color: #272727 !important;
    }

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 15px !important;
      padding-right: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #707070 !important;
    }
  }
}

.custom-tooltip .p-tooltip-text {
  font: normal normal 600 10px/14px Open Sans !important;
}

.traffic-flow-cnfm-dialog.p-dialog {
  box-shadow: 0px 0px 10px #0000003d;
  border: none;
}

.traffic-flow-cnfm-dialog.p-dialog .p-dialog-header {
  padding: 0.5em 1em;
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  border-bottom-color: #c8c8c85e;
}

.traffic-flow-cnfm-dialog.p-dialog .p-dialog-footer {
  padding: 1em;
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  border-top: none;
}

.traffic-flow-cnfm-dialog.p-dialog .p-dialog-content {
  border-radius: 0px;
  font-size: 12px;
}

.traffic-flow-cnfm-dialog.p-dialog .p-dialog-title {
  font: normal normal 600 16px/22px Open Sans;
  color: #000000;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-data {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 654px;
  padding: 3% !important;

  &::-webkit-scrollbar-track {
    background-color: #323232 !important;
  }

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 10px !important;
    padding-right: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070 !important;
  }
}

.reject-request-dialog.p-dialog .p-dialog-content {
  padding: 0.2em 1em 1em 1em;
}

//.user-dropdown {
.mat-mdc-menu- {
  &content {
    background: #323232 0% 0% no-repeat padding-box;
    border: 1px solid #515151;
    border-radius: 4px;
    padding: 0 !important;
  }

  &item {
    height: 35px;
    min-height: 40px !important;
    border-bottom: 1px solid #454545;

    &-text {
      color: #ffffff;
      font: normal normal normal 12px/17px Open Sans !important;
    }
  }

  &item:last-child {
    border: none;
  }
}
//}
.sort-dropdown {
  .p-dropdown {
    width: 171px !important;
    height: 24px !important;
    background: #1f1f21 0% 0% no-repeat padding-box !important;
    border: 1px solid #666666 !important;
    border-radius: 4px !important;
  }

  .p-dropdown-panel {
    border: none !important;
    color: #ffffff;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    font: normal normal normal 11px/15px Open Sans !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
  }

  .p-dropdown-label {
    font: normal normal normal 11px/15px Open Sans;
    letter-spacing: 0px;
    color: #d9d9d9;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .pi {
    font-size: 8px;
  }

  .p-dropdown-items {
    background-color: #000000 !important;
    font: normal normal 600 11px/15px Open Sans !important;
    color: #ffffff !important;
    box-shadow: none !important;
    color: #ffffff !important;
  }

  .p-dropdown-item:hover {
    background-color: #474747 !important;
  }

  .p-highlight {
    background: #444444 0% 0% no-repeat padding-box !important;
  }
}

.mat-mdc-menu-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.edit-user-dropdown {
  .p-dropdown {
    border: none !important;
    box-shadow: none !important;
  }

  .p-dropdown-open {
    box-shadow: none !important;
  }

  .p-dropdown:enabled:focus {
    box-shadow: none !important;
  }

  .p-dropdown-label {
    font: normal normal 600 10px/14px Open Sans !important;
    color: #325a6d !important;
    padding: 0 !important;
    text-align: center;
  }

  .p-dropdown-panel {
    width: 72px;
    height: 85px;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 6px #00000029 !important;
    border-radius: 8px !important;
  }

  .p-dropdown-item {
    font: normal normal 600 10px/14px Open Sans;
    letter-spacing: 0px;
    color: #325a6d;
  }

  .p-dropdown-trigger {
    border: none !important;
  }

  .pi {
    font-size: 8px !important;
    color: #808080;
    margin-top: 4px;
  }

  .p-dropdown-open {
    .pi {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.edit-user-dropdown-panel.p-dropdown-panel {
  width: 72px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #00000029 !important;
  border-radius: 8px !important;
  font: normal normal 600 10px/14px Open Sans !important;
  color: #325a6d !important;
  margin-left: -22px !important;
  margin-top: 4px !important;
}

.edit-user-dropdown-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  color: #000000;
}

.p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: none !important;
}

.edit-checkbox {
  & .mat-checkbox-background,
  .mat-checkbox-frame {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }

  .mat-checkbox-label {
    margin-top: 2px;
  }

  .mat-checkbox-background {
    background-color: transparent !important;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #e5b611 !important;
    }
  }
}

.search-box-input {
  font: normal normal normal 12px/26px Open Sans !important;
  letter-spacing: 0px !important;
  color: #8e919a !important;

  .p-inputtext {
    width: 430px !important;
    height: 44px !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #e0e2eb !important;
    border-radius: 4px !important;
    font: normal normal normal 12px/26px Open Sans !important;
    color: #000000 !important;
    font-weight: bold;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    padding-left: 16px !important;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
  }

  .p-autocomplete-panel {
    background: #ffffff 0% 0% no-repeat padding-box;
    max-height: 120px;
    overflow-y: scroll;
    border: 1px solid #e0e2eb;
    border-radius: 4px;
    font: normal normal normal 12px/17px Open Sans !important;
  }

  .p-autocomplete-item {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #000000;
  }

  .p-autocomplete-item:hover {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  .p-autocomplete-loader {
    display: none;
    visibility: hidden;
  }
}

.search-name-icon {
  position: absolute;
  margin-top: 28px;
  z-index: 9999;
  margin-left: 400px;

  .pi {
    font-size: 14px !important;
    color: #e0e2eb !important;
  }
}

.close-name-icon {
  position: absolute;
  margin-top: 27px;
  z-index: 9999;
  margin-left: 400px;

  .pi {
    font-size: 14px !important;
  }
}

.shared-experiment-dialog {
  .p-dialog {
    &.shared-with-wrapper {
      &::-webkit-scrollbar-track {
        background-color: #272727 !important;
      }

      &::-webkit-scrollbar {
        width: 4px !important;
        height: 15px !important;
        padding-right: 4px !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: red !important;
      }
    }
  }
}

.edit-input-area {
  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: none !important;
  }
}

.add-sku-autocomplete.p-autocomplete
  .p-autocomplete-panel::-webkit-scrollbar-track {
  background-color: #323232 !important;
}

.add-sku-autocomplete.p-autocomplete .p-autocomplete-panel::-webkit-scrollbar {
  background-color: #323232 !important;
}

.can-view-options-dd-panel.p-dropdown-panel {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
}

.can-view-options-dd-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font: normal normal 600 10px/14px Open Sans;
  color: #325a6d;
  text-align: center;
}

.can-view-options-dd-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: #f7f7f7;
  color: #325a6d;
}

.can-view-options-dd-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background: #f7f7f7;
  color: #325a6d;
}

.key-accounts-multi-select.p-multiselect {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e2eb;
  border-radius: 4px;
  width: 100%;

  &:not(.p-disabled):hover {
    border: 1px solid #e0e2eb;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: none;
  }

  .p-multiselect-label.p-placeholder {
    font: normal normal normal 14px/19px Open Sans;
    color: #d1d1d1;
  }

  .p-multiselect-trigger-icon {
    font-size: 10px;
  }
}

.key-accounts-multi-select-panel.p-multiselect-panel {
  .p-multiselect-items {
    .p-multiselect-item.p-highlight {
      background: initial;

      .p-checkbox {
        &:not(.p-checkbox-disabled).p-checkbox-box.p-focus {
          box-shadow: none;
          border: 1px solid #e5b611;
        }

        &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
          background: #e5b611 0% 0% no-repeat padding-box !important;
          border: 1px solid #e5b611 !important;
        }

        .p-checkbox-box.p-highlight {
          background: #e5b611 0% 0% no-repeat padding-box;
          border: 1px solid #e5b611;
        }
      }
    }

    .p-multiselect-item {
      &:not(.p-highlight):not(.p-disabled):hover {
        background: initial;
      }

      &:focus {
        box-shadow: none;
      }

      .p-checkbox {
        .p-checkbox-box {
          width: 16px;
          height: 16px;

          .p-checkbox-icon {
            color: #000000;
          }
        }
      }
    }
  }
}

.admin-panel-toast.p-toast {
  border-radius: 4px;
  opacity: 1;
  top: 85px;

  .p-toast-message {
    box-shadow: none;

    .p-toast-message-content {
      .p-toast-summary {
        font: normal normal 600 16px/22px Open Sans;
        color: #7cd127;
      }

      .p-toast-message-icon {
        display: none;
      }

      .p-toast-detail {
        font: normal normal normal 12px/17px Open Sans;
        color: #323232;
      }

      .p-toast-icon-close-icon {
        color: #7cd127;
      }

      .p-toast-icon-close {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.admin-panel-toast.p-toast .p-toast-message.p-toast-message-success {
  background: #e0f4cb;
  border-radius: 4px;
  border-left: 5px solid #7cd127;
}

.share-exp-autocomplete-panel.p-autocomplete-panel {
  box-shadow: none;
  margin-top: 5px;

  .p-autocomplete-items {
    .p-autocomplete-item {
      padding: 10px 10px 10px 10px;
    }
  }
}

.error-autocomplete {
  .share-exp-autocomplete.p-autocomplete {
    .p-inputtext {
      border-color: #ff5832 !important;
    }
  }
}

.count-cards-skeleton.p-skeleton {
  margin: auto;
  margin-top: 5px;
}

.prev-button {
  width: 100px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5b611;
  border-radius: 4px;
  font: normal normal 600 16px/22px Open Sans;
  color: #d6a732;

  &:hover,
  &:focus {
    color: #000000;
  }
}

.option-dropdown {
  width: 103px !important;
  height: 66px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #0000001f !important;
  border-radius: 8px !important;

  .p-overlaypanel-content {
    padding: 0px !important;
  }
}

// .clone-experiment-dialog {
//   width: 467px !important;
//   height: 230px !important;
//   background: #ffffff 0% 0% no-repeat padding-box !important;
//   border: 1px solid #e0e2eb !important;
//   border-radius: 8px !important;
// }

// .clone-experiment-dialog.p-dialog .p-dialog-header {
//   border-bottom: 1px solid #e0e2eb !important;
//   background: none !important;
//   margin: 8px 1rem !important;
//   padding: 0 !important;
// }

// .clone-experiment-dialog.p-dialog .p-dialog-footer {
//   border-top: none !important;
//   padding: 16px 1rem !important;

//   button {
//     margin: 0 0 0 8px !important;
//   }
// }

// .clone-experiment-dialog.p-dialog .p-dialog-content {
//   padding: 12px 1rem !important;
// }

.experiment-name-field.p-inputtext {
  width: 100% !important;
  height: 40px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e0e2eb !important;
  border-radius: 4px !important;

  &:enabled:focus {
    box-shadow: none !important;
  }
}

// admin panel dialog close on outside click style
.user-management-dialog-mask {
  pointer-events: auto !important;
}

// Utility Classes

.opacity-50 {
  opacity: 0.5 !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.flex-grow {
  flex: 1;
  overflow: auto;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.text-linethrough {
  text-decoration: line-through !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

// tooltip max width
.mat-tooltip-panel .mat-tooltip {
  max-width: unset !important;
}

.form-control {
  height: 60px;
  border: 1px solid var(--pho_white_shade3);
  @apply rounded-lg;

  &:read-only {
    color: var(--pho_black_shade2);
    background-color: var(--bs-secondary-bg);
    opacity: 1;
  }

  &:focus {
    border: 1px solid var(--pho_white_shade3);
    box-shadow: none;
    outline: none;
  }

  @media (min-width: $smWidth) and (max-width: $mdWidth) {
    height: 50px;
  }
}

.ng-dropdown-panel {
  z-index: 10000000 !important;
  width: 100px !important;
  border: 1px solid var(--pho_black_shade6);
  @apply rounded-b-lg;
}

.ng-dropdown-panel {
  border: 1px solid var(--pho_black_shade6);

  &.ng-select-top {
    border-color: var(--pho_black_shade6);
    @apply rounded-t-lg;
  }

  &.ng-select-bottom {
    border-color: var(--pho_black_shade6);
    @apply rounded-b-lg;
  }
}

.collapse {
  visibility: unset;
}

.manufacturer-table.p-datatable .p-datatable-tbody > tr {
  background: #323232 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  font: normal normal normal 11px/15px Open Sans !important;
}

.manufacturer-table.p-datatable .p-datatable-thead > tr > th {
  background: #272727 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  font: normal normal 600 12px/14px Open Sans !important;
  border: 1px solid #222222 !important;
}

.manufacturer-table.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #222222 !important;
  border-width: 1px 0px 1px 0px !important;
}

.manufacturer-table.p-datatable .p-datatable-tbody > tr > td:first-child {
  min-width: 200px;
  max-width: 200px;
}

.manufacturer-table.p-datatable .p-datatable-thead > tr > th:first-child {
  min-width: 200px;
  max-width: 200px;
}

.manufacturer-table.p-datatable-scrollable .p-datatable-wrapper {
  &::-webkit-scrollbar {
    width: 3px !important;
    height: 7px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #b9b9b9 !important;
    border-radius: 2px !important;
  }

  &::-webkit-scrollbar-track {
    background: inherit !important;
    border-radius: 2px !important;
    margin-top: 40px;
  }
}

.manufacturer-overlay.p-overlaypanel {
  border: none;
  box-shadow: 0px 0px 6px #00000045;
  border-radius: 4px 4px 0px 0px;
  margin-top: 20px;
}

.manufacturer-overlay.p-overlaypanel .p-overlaypanel-content {
  padding: 0.971rem 1rem !important;
}

.count-cards-skeleton.p-skeleton {
  margin: auto;
  margin-top: 5px;
}

.prev-button {
  width: 100px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5b611;
  border-radius: 4px;
  font: normal normal 600 16px/22px Open Sans;
  color: #d6a732;

  &:hover,
  &:focus {
    color: #000000;
  }
}

.option-dropdown {
  width: 103px !important;
  height: auto !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #0000001f !important;
  border-radius: 8px !important;

  .p-overlaypanel-content {
    padding: 0px !important;
  }
}

.measurements-dropdown.ng-dropdown-panel.ng-select-bottom {
  border-top-color: #787878 !important;
}

.measurements-dropdown.ng-dropdown-panel {
  .ng-option {
    background-color: #1f1f21;
    color: #ffffff;

    &:hover {
      background-color: #323232;
      color: #ffffff;
    }

    &-selected {
      background-color: #323232 !important;
      color: #ffffff !important;
    }
  }
}

.category-select.ng-dropdown-panel {
  .ng-option {
    &:hover {
      background-color: #474747 !important;
      color: #ffffff;
    }

    &-selected {
      background-color: #3e3e3e !important;
      color: #ffffff !important;
    }
  }
}

.infoOverlay {
  top: 88px !important;

  .p-overlaypanel-content {
    padding: 0px !important;
  }
}

.plan-options-overlay {
  .p-overlaypanel {
    background-color: #1f1f21 0% 0% no-repeat padding-box !important;
    width: 50px !important;
  }

  .p-overlaypanel-content {
    padding: 0px !important;
    text-align: left !important;

    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        font: normal normal 600 10px/14px Open Sans;
        color: #fff;

        img {
          height: 10px;
          margin-right: 8px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #1f1f21 !important;
  }

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 15px !important;
    padding-right: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070 !important;
  }

  &:before {
    content: none !important;
  }

  &:after {
    content: none !important;
  }
}

.clone-experiment-dialog {
  width: 500px !important;
  // height: 230px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e0e2eb !important;
  border-radius: 8px !important;

  .p-dialog-header {
    // border-bottom: 1px solid #e0e2eb !important;
    // background: none !important;
    // margin: 8px 1rem !important;
    // padding: 0 !important;

    background: var(--pho_white);
    border-radius: 8px 8px 0px 0px;
    border-color: #c8c8c8;
    @apply border-b border-solid px-6 py-2;
  }

  .p-dialog-footer {
    @apply border-none px-6 pt-0 pb-2;

    button {
      margin: 0 0 0 8px !important;
    }
  }

  .p-dialog-content {
    @apply px-6 py-3;

    .clone-exp-name {
      @apply mb-2;
    }

    .experiment-name-field {
      @apply text-sm px-3;
    }
  }
}

.experiment-name-field.p-inputtext {
  width: 100% !important;
  height: 40px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e0e2eb !important;
  border-radius: 4px !important;

  &:enabled:focus {
    box-shadow: none !important;
  }
}

div.remove-leftout-products .mat-dialog-container {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 20px #0000001a !important;
  border-radius: 8px !important;
  padding: 0 !important;
}

// admin panel dialog close on outside click style
.user-management-dialog-mask {
  pointer-events: auto !important;
}

.clipboard-mat-menu {
  max-width: 180px !important;
  overflow: hidden !important;
  background: #323232 !important;
}

.delete-plano-dialog {
  width: 532px !important;
  height: 176px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 20px #0000001a !important;
  border-radius: 8px !important;

  .p-dialog-header {
    background: transparent !important;
    border-bottom: none !important;
    padding: 0.5em 0em !important;
    background-color: #ffffff !important;
    border-radius: 8px 8px 0px 0px !important;
    margin: 0px 16px !important;
    border-color: #e0e2eb !important;
    border-bottom: 1px solid #ededed !important;
    width: 495px !important;
  }

  .p-dialog-content {
    font: normal normal normal 12px/24px Open Sans;
    color: #323232 !important;
    overflow-y: hidden;
  }

  .p-dialog-footer {
    border-top: none !important;
    padding: 16px 12px !important;
  }
}

.plano-info-dialog.p-dialog {
  border-radius: 8px;
  max-width: 85vw;

  .p-dialog-header {
    background-color: #ffffff;
    padding: 12px;
    border-bottom: none;
    min-height: 4.3em;

    .p-dialog-title {
      color: #000;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
    }

    .pi {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }
  }

  .p-dialog-content {
    padding: 0;
    border-radius: 0px !important;
  }

  .p-dialog-footer {
    padding: 0.8em 1em;

    p {
      color: #666;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
    }

    .btn {
      width: 105px;
      height: 32px;
      border-radius: 4px;
    }

    .cancel-btn {
      border: 1px solid #666;
      background: #fff;
      color: #666;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
    }

    .save-btn {
      border-radius: 4px;
      background: #e5b611;
      color: #000;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.plan-info-tb-radio-btn {
  .p-radiobutton-icon {
    background: url("/assets/images/tick-icon.svg") center no-repeat;
  }

  .p-radiobutton-box {
    width: 16px !important;
    height: 16px !important;
  }

  .p-radiobutton-box.p-highlight {
    background: #e5b611 !important;
    border-color: #e5b611 !important;

    .p-radiobutton-icon {
      background-color: #e5b611;
    }
  }
}

.plan-info-tb-radio-btn.p-radiobutton
  .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-duplicated-table.p-datatable {
  .p-checkbox {
    .p-checkbox-box.p-highlight {
      border-color: #e5b611;
      background: #e5b611;
    }

    .p-checkbox-icon {
      font-weight: 600;
      color: #000000;
    }
  }

  .p-checkbox:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: #e5b611;
        background: #e5b611;
      }
    }
  }
}

.open-psa-dialog {
  width: 814px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  padding: 1rem !important;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.11));
  border-radius: 8px !important;
}

.open-psa-dialog.p-dialog .p-dialog-header {
  background: none !important;
  // margin: 8px 1rem !important;
  padding: 0 1rem !important;
  border: none !important;
}

.open-psa-dialog.p-dialog .p-dialog-footer {
  border-top: none !important;
  padding: 16px 1rem !important;

  button {
    margin: 0 0 0 16px !important;
  }
}

.open-psa-dialog.p-dialog .p-dialog-content {
  padding: 12px 1rem !important;
  overflow: hidden;

  .p-dialog-header {
    background: transparent !important;
    border-bottom: 1px solid #ededed;
    width: 495px;
  }
}

div.delete-planogram-dialog .mat-dialog-container {
  overflow: hidden !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 20px #0000001a !important;
  border-radius: 8px !important;
  padding: 0 !important;
}

.plans-dropdown.p-dropdown {
  background-color: #3e3e3e;
  border: none;
  max-width: 195px;

  .p-inputtext {
    color: #ffffff;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    padding: 1px;
    padding-left: 5px;
  }

  .p-dropdown-trigger {
    width: 2em;
  }

  .p-dropdown-trigger-icon {
    font-size: 11px;
    color: #deba02;
  }
}

.plans-panel-dropdown.p-dropdown-panel {
  top: 33px !important;
  z-index: 1000;
  background: #333333 !important;
  color: #333333;
  border: 1px solid #333333 !important;
  border-radius: 4px;
  width: 195px;

  .p-dropdown-items {
    .p-dropdown-item {
      background-color: #3e3e3e;
      color: #ffffff;
      padding: 4px 10px;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }

    .p-dropdown-empty-message {
      @apply text-white text-xs;
    }
  }

  .p-dropdown-header {
    @apply bg-[#3e3e3e] p-0;

    .p-dropdown-filter-icon {
      width: 0.7em;
      height: 0.7em;
    }

    .p-dropdown-filter.p-inputtext {
      @apply text-white bg-[#3e3e3e] px-2 py-1;
      &:focus {
        @apply outline-none border-none shadow-none;
      }
      &::placeholder {
        @apply text-xs font-normal;
      }
    }
  }
}
.plans-panel-dropdown.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-disabled):hover {
  background-color: #232323 !important;
}

.plans-panel-dropdown.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #3e3e3e;
}

.plans-panel-dropdown.p-dropdown-panel .p-dropdown-items-wrapper {
  max-height: 400px !important;
}

.shelf-dimension-tabs {
  .mat-mdc-tab {
    padding: 0;
    min-width: 122px;
    height: 30px !important;
  }

  .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab .mdc-tab__text-label:hover {
    color: #ffffff;
    font-size: 14px;
    font-family: Open Sans;
    letter-spacing: normal;
  }

  .mat-mdc-tab-labels {
    justify-content: center;
  }

  .mdc-tab-indicator--active .mdc-tab__text-label {
    color: #e5b611 !important;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
    border-color: #e5b611 !important;
  }
}

.shelf-dimension-tabs
  .mat-mdc-tab-header-pagination-controls-enabled
  .mat-mdc-tab-header-pagination {
  display: none;
}

.category-select.ng-select .ng-select-container {
  background: #323232;
  color: #ffffff;
  border: 1px solid #787878;
}

.category-select.ng-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  box-shadow: none;
  border: 1px solid #787878;
}

.ng-dropdown-panel.category-select {
  background-color: #323232;
  color: #ffffff;
  border: 1px solid #787878;
}

.ng-dropdown-panel.category-select .ng-dropdown-panel-items .ng-option {
  background-color: #323232;
  color: #ffffff;
}

.ng-dropdown-panel.category-select
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #474747;
  color: #ffffff;
}

.plans-panel-dropdown.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #3e3e3e;
  padding: 4px 9px;
}

.p-info-wrapper::-webkit-scrollbar-track {
  background: inherit !important;
  border-radius: 0px !important;
}

.plano-menu-scrollbar::-webkit-scrollbar-track {
  background: inherit !important;
  border-radius: 0px !important;
}

// Utility Classes

.opacity-50 {
  opacity: 0.5 !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.select-psa-plan-rb.p-radiobutton {
  .p-radiobutton-box.p-highlight {
    border-color: #323232;
    background: #ffffff;
  }

  .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: #323232;
  }

  .p-radiobutton-icon {
    background-color: transparent;
    width: 10px !important;
    height: 10px !important;
  }

  .p-radiobutton-box {
    width: 16px;
    height: 16px;
    border: 1px solid #323232;
  }

  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #323232;
    background: transparent;
    color: #323232;
  }

  .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
  }
}

.create-new-plan-dialog {
  .mat-dialog-container {
    padding: 16px 24px;
    border-radius: 8px;
  }

  .delete-title {
    color: #000;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
  }

  .dialog-close {
    width: 12px;
    cursor: pointer;
  }

  .delete-content {
    .btn {
      color: #666;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      border: 1px solid #666;
      background: #fff;
      width: 100%;
      min-height: 43px;

      &.active-custom-plan-btn {
        border: none;
        background-color: #fbf4dc;
        color: #000;
      }
    }
  }

  .delete-footer {
    .cancel-btn {
      border-radius: 4px;
      border: 1px solid #666;
      background: #fff;
      color: #666;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 32px;
    }

    .okay-btn {
      border-radius: 4px;
      border: 1px solid #e5b611;
      background: #e5b611;
      color: #000;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 24px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

.plan-info-plan-table.p-datatable,
.create-new-plan-table.p-datatable {
  table {
    border-spacing: 0 6px;
    border-collapse: separate;
  }

  .p-datatable-thead tr th {
    background-color: transparent;
    color: #666;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    border: none;
    padding: 0px;
  }

  .p-datatable-tbody tr.p-highlight {
    border: 1px solid #ececec !important;
    background: #f4f4f4 !important;
  }

  .p-datatable-tbody tr {
    border-radius: 8px;
    border: 1px solid #ececec;
    background: #fff;

    td {
      color: #000;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid #ececec;
      border-right: none;
      border-left: none;

      &:last-of-type {
        border-right: 1px solid #ececec;
        border-radius: 0px 8px 8px 0px;
      }

      &:first-of-type {
        border-left: 1px solid #ececec;
        border-radius: 8px 0px 0px 8px;
      }
    }
  }

  .p-radiobutton-box.p-highlight {
    border-color: #323232;
    background: #ffffff;
  }

  .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: #323232;
  }

  .p-radiobutton-icon {
    background-color: transparent;
    width: 10px !important;
    height: 10px !important;
  }

  .p-radiobutton-box {
    width: 16px;
    height: 16px;
    border: 1px solid #323232;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #323232;
    background: transparent;
    color: #323232;
  }

  .p-radiobutton .p-radiobutton-box.p-focus {
    box-shadow: none;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.flex-grow {
  flex: 1;
  overflow: auto;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.text-linethrough {
  text-decoration: line-through !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.word-break-all {
  word-break: break-all;
}

.sku-edit-del-menu {
  background: #1f1f21;
  color: #fff;

  .mat-menu-content {
    border: 1px solid #454545;
  }

  .edit-delete-options {
    font-size: 12px;

    &:hover {
      background-color: #323232;
    }
  }
}

// tooltip max width
.mat-tooltip-panel .mat-tooltip {
  max-width: unset !important;
}
.bulk-sku-upload-progress-bar .mat-progress-bar-fill::after {
  border-radius: 12px;
  background-color: #e5b611;
}

.bulk-sku-upload-progress-bar .mat-progress-bar-buffer {
  border-radius: 12px;
  background: #d9d9d9;
}

.bulk-sku-upload-progress-bar .mat-progress-bar {
  width: 390px;
  height: 6px;
  border-radius: 12px;
  background: #d9d9d9;
  margin: 4px 0px 8px 0px;
}

.bulk-sku-checkbox.p-checkbox {
  &:not(.p-checkbox-disabled).p-checkbox-box.p-focus {
    box-shadow: none !important;
    border: 1px solid #e5b611 !important;
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #e5b611 0% 0% no-repeat padding-box !important;
    border: 1px solid #e5b611 !important;
  }

  .p-checkbox-box.p-highlight {
    background: #e5b611 0% 0% no-repeat padding-box;
    border: 1px solid #e5b611;
  }
  .p-checkbox-icon {
    @apply text-[--pho_black];
  }
}

.p-link {
  box-shadow: none !important;
  outline: none !important;
}

.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

.mdc-checkbox {
  padding: 5px 10px !important;
  flex: 0 0 10px !important;
}

.mat-mdc-checkbox-touch-target {
  height: 20px !important;
}

.mdc-checkbox .mdc-checkbox__background {
  top: 5px !important;
  left: 0px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control:indeterminate:checked
  ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple,
.mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple,
.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  background-color: #e5b611 !important;
  border-color: #e5b611 !important;
}

.intermediate .mat-checkbox-background {
  background-color: #000 !important;
}

.mdc-checkbox__mixedmark path {
  fill: #000 !important;
  stroke: #000 !important;
  border-color: #000 !important;
}

.mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #000 !important;
}

.mdc-checkbox__checkmark {
  color: #000 !important;
}

.mdc-checkbox__mixedmark {
  color: #000 !important;
  border-color: #000 !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon,
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  display: none;
}

.mdc-switch__handle::before {
  width: 12px !important;
  height: 12px !important;
  left: 6px !important;
  top: 3.5px !important;
}

.mdc-switch__handle::after {
  width: 14px !important;
  height: 14px !important;
  left: 1px !important;
  top: 3px !important;
}

.right-menu-li {
  .mdc-switch--checked .mdc-switch__handle::after {
    left: 5px !important;
  }
}

.mdc-switch:enabled .mdc-switch__track::before,
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: #ffffff !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background-color: #e5b611 !important;
}

// common p-dialog border-radius
.p-dialog .p-dialog-content:last-of-type {
  @apply rounded-b-lg;
}

.p-dialog .p-dialog-footer {
  @apply rounded-b-lg;
}

::ng-deep .p-tooltip {
  .p-tooltip-text {
    @apply text-xs font-normal;
  }
}

// Toogle Button Styles
.buttons-set {
  border-color: var(--pho_white_shade1);
  @apply border-solid border rounded;

  .btn {
    opacity: 0.3;

    &.is-active {
      border: 1px solid var(--pho_white_shade1);
      background-color: var(--pho_white_shade1);
      opacity: 1;
    }
  }
}

.simulation-dialog.p-dialog .p-dialog-header {
  background-color: #ffffff;
}

.new-dropdown {
  border-radius: 4px;
  width: 246px !important;
  padding: 16px;
}

.new-dropdown .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  @apply text-sm;
}

.summary-box-input {
  font: normal normal normal 12px/26px Open Sans !important;
  letter-spacing: 0px !important;
  border: 1px solid #e0e2eb;
  border-radius: 4px;
  margin-top: 10px;
  width: 430px !important;
  color: #000000 !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  padding: 6px 0px 6px 16px !important;

  .p-inputtext:enabled:focus {
    outline: none !important; /* Remove the default focus outline */
    border-color: none !important; /* Set the border color when focused */
    box-shadow: none !important; /* Remove any box shadow when focused */
  }
}

.dialog-present-mode {
  position: fixed !important;
  width: 100vw !important;
  min-height: 100vh !important;
  height: 100vh !important;
  top: 50px;
  z-index: 100000;
  border-radius: 0px !important;
}

.plano-space-share {
  width: 1280px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e0e2eb !important;
  border-radius: 8px;
}

.plano-space-share.p-dialog .p-dialog-header {
  border-bottom: 1px solid #e0e2eb !important;
  background: none !important;
  margin: 8px 1rem !important;
  padding: 0 !important;
}

.plano-space-table.p-datatable .p-datatable-tbody > tr > td > span {
  display: inline-block;
  max-width: 192px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.plano-space-share.p-dialog .p-dialog-footer {
  border-top: none !important;
  padding: 16px 1rem !important;

  button {
    margin: 0 0 0 8px !important;
  }
}

.plano-space-share.p-dialog .p-dialog-content {
  padding: 18px !important;
}

.plano-space-table.p-datatable .p-datatable-thead tr th {
  height: 58px;
  border-radius: 3px 3px 0px 0px;
  background-color: #efefef;
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  color: #323232;
  border: none;
}

.plano-space-table.p-datatable .p-datatable-thead tr th:first-child {
  border-right: 1px solid var(--pho_black);
}

/* Apply left border to last th */
.plano-space-table.p-datatable .p-datatable-thead tr th:last-child {
  border-left: 1px solid var(--pho_black);
}

/* Apply both left and right borders to th in between */
.plano-space-table.p-datatable
  .p-datatable-thead
  tr
  th:not(:first-child):not(:last-child) {
  border-left: 1px solid var(--pho_black);
  border-right: 1px solid var(--pho_black);
}

.plano-space-table.p-datatable .p-datatable-header {
  border: none !important;
}

.plano-space-table.p-datatable .p-datatable-tbody {
  max-height: 540px !important;
  overflow-y: auto !important;
}

.plano-space-table {
  //max-height: 718px;
  overflow-y: auto;
}

.plano-space-table.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #e9e9e9 !important;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #666;
}
.plano-space-table.p-datatable .p-datatable-tfoot > tr > td {
  border: 1px solid var(--pho_black) !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #323232;
}

.custom-tabview .p-tabview-nav {
  border-bottom: none; /* Remove the default border */
}

.custom-tabview .p-tabview-nav li {
  color: grey;
  font-weight: 600;
}

.custom-tabview .p-tabview-nav-container {
  height: 24px !important;
}

.custom-tabview .p-tabview-nav li.p-highlight {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  font-family: Open Sans;
  border-bottom: 3px solid #000000;
}

.custom-tabview .p-tabview-nav li .p-tabview-title {
  text-decoration: none;
}

.tooltip-big-width.p-tooltip .p-tooltip-text {
  width: 210px !important;
  font-size: 12px !important;
  font-family: Open Sans;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.custom-menu {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 4px #a8abb463 !important;
  border-radius: 4px !important;
  margin-left: 55px !important;

  .mat-mdc-menu-content {
    background-color: unset;
    border: unset;
  }
}

.custom-menu.mat-menu-panel {
  margin-right: 24px !important;
}
.lil-gui.autoPlace {
  max-height: 100%;
  position: fixed;
  top: 0;
  right: 15px;
  z-index: 990 !important;
  display: none;
}

// common style for EDA and Clustring
.user-selection-box {
  width: 100%;

  .download-temp {
    letter-spacing: 0em;
    color: var(--pho_primary);
    @apply text-sm float-right text-right underline cursor-pointer mb-3;
  }

  .eda-overview-options {
    width: 100%;
    background: rgba(241, 231, 161, 0.3);
    @apply rounded py-3.5 px-3.5 mb-4;

    .eda-overview-package {
      border: none;
      font-size: 14px;

      input:checked ~ label {
        font-weight: bold;
      }
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.4;
      box-shadow: none;

      &:hover {
        box-shadow: none;
        cursor: default;
      }
    }

    .eda-overview-package {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      @apply p-2.5;

      input {
        opacity: 0;
      }

      input:checked ~ .eda-overview-package-label::after {
        content: "";
        position: absolute;
        background: #000000;
        left: 3px;
        width: 14px;
        height: 14px;
        border-radius: 10px;
        top: 13px;
      }
    }

    .eda-overview-package-label {
      cursor: inherit;
      @apply text-sm text-black;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1px solid #000000;
        display: block;
        left: 0px;
        top: 10px;
        border-radius: 50%;
      }
    }
  }

  .eda-overview-success {
    color: #0f871e;
  }
}

@layer utilities {
  .custom-dark-bg {
    background-color: #2c2c2c !important;
  }
  .text-white {
    color: white !important;
  }
  .bg-white {
    background-color: white !important;
  }
  .custom-panel-bg {
    background: #232323 !important;
  }

  .highlight-menu {
    @apply p-2 rounded-md;
    background-color: #232323 !important;
  }
  .icon-background {
    background: #777777 !important;
  }
  .active-icon-background {
    background: #e5b611 !important;
  }
  .text-yellow {
    color: #e5b611 !important;
  }
  .grey-border {
    border-left: 1px solid #858585 !important;
  }
  .bg-black {
    background-color: black !important;
  }
  .grey-text {
    color: #bdbdbd !important;
  }
  .grey-text1 {
    color: #858585 !important;
  }
  .border-color-grey {
    border-color: #bdbdbd !important;
  }
  .hover-background {
    background: #383838 !important;
  }
  .black-shade1 {
    background-color: #323232 !important;
  }
  .black-shade2 {
    background-color: #424242 !important;
  }
  .hover-background1 {
    background: #e5b61133 !important;
  }
  .black-border1 {
    border-color: #232323 !important;
  }
  .black-border2 {
    border-color: #424242 !important;
  }
  .border-transparent {
    border: none !important;
  }
  .input-border {
    border: 1px solid #bdbdbd !important;
  }
  .text-grey1 {
    color: #c3c3c3 !important;
  }
  .text-grey2 {
    color: #e9e9e9 !important;
  }
  .bg-grey2 {
    background: #e9e9e9 !important;
  }
  .border-yellow {
    border: 1px solid #e5b611 !important;
  }
  .hover-yellow {
    background: #ffdf001f !important;
  }
  .grey-border2 {
    border: 1px solid #444444 !important;
  }
  .grey-border3 {
    border-color: #4c4c4c !important;
  }
  .highlight-shade {
    background: #4a401f !important;
    border: 1px solid #e5b611 !important;
  }
  .mb1 {
    margin-bottom: 2px !important;
  }
  .gradient-background {
    background: linear-gradient(
      90deg,
      rgba(41, 41, 41, 0.8) 0%,
      rgba(0, 0, 0, 0.56) 100%
    ) !important;
  }
  .border-white {
    border: 1px solid white;
  }
  .background-transparent {
    background: transparent !important;
  }
  .disabled-input {
    background: #262626 !important;
    color: #595959 !important;
  }
}
