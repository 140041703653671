// Font families
$opensans:
  "Open Sans",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--light:
  "Open Sans Light",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--lightitalic:
  "Open Sans Light Italic",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--medium:
  "Open Sans Medium",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--mediumitalic:
  "Open Sans Medium Italic",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--semi:
  "Open Sans SemiBold",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--semiitalic:
  "Open Sans SemiBold Italic",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--bold:
  "Open Sans Bold",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

$opensans--bolditalic:
  "Open Sans Bold Italic",
  -apple-system,
  BlinkMacSystemFont,
  "Roboto",
  "Helvetica Neue",
  sans-serif;

// MWF Font weights
$bold: 700;
$semi-bold: 600;
$medium-bold: 500;
$regular-bold: 400;

// ref - https://github.com/Necromancerx/media-queries-scss-mixins
// media aliases and breakpoints
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1500px; // 1280
$screen-xl-min: 1920px;

$screen-xs-max: 599px;
$screen-sm-max: 959px;
$screen-md-max: 1499px; //1279
$screen-lg-max: 1919px;
$screen-xl-max: 5000px;

// media devices
@mixin xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media lt queries
@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
